import { useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { Grid } from "@mui/material"
import { alpha, useTheme } from "@mui/system"
import EditIcon from "@mui/icons-material/Edit"
import fp from "lodash/fp"

import { useLastLocationsQuery, useUpdateLocationMutation } from "features/api"
import { MAP_HEIGHT_COMPACT } from "helpers/utils/constants"
import ConfirmationPopup from "widgets/common/ConfirmationPopup"
import type { LastLocationsRes, LastLocations } from "types/telemetries.types"
import GeolocationPlot from "widgets/plots/geo/GeolocationPlot"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import { toSentenceCase } from "helpers/utils/translations"
import CardWithTitle from "widgets/common/CardWithTitle"
import usePermissions from "helpers/hooks/usePermissions"
import { GeolocationInput } from "./GeolocationInput"

export interface IDeviceLocationForm {
  lat: string
  lon: string
}

interface DeviceLocationProps {
  deviceId: number
}

function DeviceLocation({ deviceId }: DeviceLocationProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isEditor } = usePermissions()

  const initialCoords: IDeviceLocationForm = useMemo(() => {
    return { lat: "", lon: "" }
  }, [])

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false)
  const [coords, setCoords] = useState<IDeviceLocationForm | null>(initialCoords)
  const [lastLocation, setLastLocation] = useState<LastLocationsRes | null>([])
  const [updateLocation, { isLoading: isLoadingUpdate }] = useUpdateLocationMutation()

  const { currentData: currentLocationData, isLoading: isLoadingQuery } =
    useLastLocationsQuery({ params: { less_id: deviceId } }, { skip: !deviceId })

  const methods = useForm<IDeviceLocationForm>({
    mode: "all",
    defaultValues: {
      lat: coords ? coords.lat : "0",
      lon: coords ? coords.lon : "0",
    },
  })

  const { resetField, setValue, getValues } = methods

  const onSubmit = () => {
    setOpenConfirmationPopup(true)
  }

  const confirmUpdate = () => {
    snackbarMutation({
      mutation: updateLocation({
        deviceId,
        latitude: Number(getValues("lat")),
        longitude: Number(getValues("lon")),
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(t("device_settings.FAILED_LOCATION_UPDATE")),
      getSuccessMessage: () =>
        t("success.UPDATING_ITEM", {
          item: toSentenceCase(t("device_information.DEVICE_LOCATION")),
          count: 1,
          context: "female",
        }),
    })
      .then(() => {
        setIsEditing(false)
        setLastLocation(null)
        setCoords(null)
        setOpenConfirmationPopup(false)
      })
      .catch()
  }

  const onEditClick = () => {
    setIsEditing(true)
    if (coords) {
      setValue("lat", coords.lat)
      setValue("lon", coords.lon)
    }
  }
  const onCancelEdit = () => {
    setIsEditing(false)
    resetField("lat")
    resetField("lon")
  }
  const onClosePopup = () => {
    setOpenConfirmationPopup(false)
  }

  useEffect(() => {
    const coords = fp.flow(
      fp.filter(({ result }) => result === "location"),
      fp.map(({ lat, lon }: LastLocations) => ({ lat, lon })),
      fp.last,
    )(currentLocationData)

    if (coords && currentLocationData) {
      setLastLocation(currentLocationData)
      setCoords(coords)
    }
  }, [currentLocationData])

  return (
    <>
      <CardWithTitle
        titleKey={"device_information.DEVICE_LOCATION"}
        actionPermission={isEditor}
        isDoingAction={isEditing}
        handleAction={onEditClick}
        actionIcon={<EditIcon fontSize="small" />}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                <GeolocationInput
                  name={"lat"}
                  isEditing={isEditing}
                  disabled={isLoadingUpdate}
                  isLoading={isLoadingQuery || isLoadingUpdate}
                  coords={coords}
                />
                <GeolocationInput
                  name={"lon"}
                  isEditing={isEditing}
                  disabled={isLoadingUpdate}
                  isLoading={isLoadingQuery || isLoadingUpdate}
                  coords={coords}
                />
              </Grid>
            </Grid>
            <GeolocationPlot
              isLoading={isLoadingQuery || isLoadingUpdate}
              mapHeight={MAP_HEIGHT_COMPACT}
              data={lastLocation}
            />
            {isEditing && (
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
                mt={2}
              >
                <Button
                  sx={{
                    color: theme.palette.grey[500],
                    borderColor: theme.palette.grey[500],
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.grey[500], 0.1),
                      borderColor: theme.palette.grey[400],
                    },
                  }}
                  variant="outlined"
                  aria-label="cancel"
                  onClick={onCancelEdit}
                  disabled={isLoadingUpdate}
                >
                  {t("generic.CANCEL")}
                </Button>
                <Button type="submit" variant="outlined" disabled={isLoadingUpdate}>
                  {t("generic.UPDATE")}
                </Button>
              </Stack>
            )}
          </FormProvider>
        </form>
      </CardWithTitle>
      <ConfirmationPopup
        open={openConfirmationPopup}
        onClose={onClosePopup}
        description={t("device_settings.ARE_YOU_SURE_TO_UPDATE_SETTINGS")}
        mainButtonText={t("generic.CONFIRM")}
        onConfirm={confirmUpdate}
        isLoading={isLoadingUpdate}
      />
    </>
  )
}

export default DeviceLocation
