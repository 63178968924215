import { Button, Stack, alpha } from "@mui/material"
import { useTranslation } from "react-i18next"

import LoadingButton from "./LoadingButton"

interface FormButtonsProps {
  onClose: () => void
  disabled?: boolean
  buttonText: string
  isLoading?: boolean
}

function FormButtons({
  onClose,
  disabled = false,
  buttonText,
  isLoading = false,
}: FormButtonsProps) {
  const { t } = useTranslation()
  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "end",
        gap: 2,
      }}
    >
      <Button
        sx={{
          color: (theme) => theme.palette.grey[500],
          "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.grey[500], 0.1),
          },
        }}
        aria-label="cancel"
        onClick={onClose}
      >
        {t("generic.CANCEL")}
      </Button>
      <LoadingButton
        sx={{ width: "fit-content" }}
        type="submit"
        variant="outlined"
        disabled={disabled}
        loading={isLoading}
      >
        {t(buttonText)}
      </LoadingButton>
    </Stack>
  )
}

export default FormButtons
