import { useTranslation } from "react-i18next"
import QRCode from "react-qr-code"
import type { Theme } from "@mui/material"
import { Stack, Typography, List, ListItem, Dialog, useMediaQuery } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import type { UserOTPRegisterRes } from "types/users.types"
import CardWithTitle from "widgets/common/CardWithTitle"
import OTPVerifier from "./OTPVerifier"

interface OTPRegisterModalProps {
  open: boolean
  onClose: () => void
  onComplete: () => Promise<void>
  username: string
  data: UserOTPRegisterRes | undefined
}

const OTPRegisterModal = ({
  open,
  onClose,
  onComplete,
  username,
  data,
}: OTPRegisterModalProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  if (data === undefined) {
    return <></>
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="otp-register-title"
      aria-describedby="otp-register-description"
      fullWidth
      maxWidth={"md"}
    >
      <CardWithTitle
        titleKey="account_settings.TWO_FA"
        handleAction={onClose}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <Stack id="otp-register-description" width={"100%"}>
          <Typography variant="h5" component="h5" mb={1}>
            {t("account_settings.CONFIGURING_OTP")}
          </Typography>
          <List
            dense
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              {t("account_settings.INSTALL_APP")}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              {t("account_settings.IN_THE_AUTH_APP")}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              {t("account_settings.SELECT_SCAN")}
            </ListItem>
          </List>
        </Stack>
        {data?.auth_url && (
          <Stack alignItems={"center"} mt={3} mb={5}>
            <QRCode value={data.auth_url} size={isMobile ? 200 : 350} />
          </Stack>
        )}
        <Typography
          id="otp-verification-title"
          variant="h4"
          component="h4"
          mb={2}
          align="center"
        >
          {t("account_settings.VERIFICATION_CODE")}
        </Typography>
        <OTPVerifier username={username} onComplete={onComplete} />
      </CardWithTitle>
    </Dialog>
  )
}
export default OTPRegisterModal
