import type { FC } from "react"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/Edit"
import CancelIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import type {
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid"
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid"
import fp from "lodash/fp"

import {
  useOrgUsersQuery,
  useDeleteOrgUserMutation,
  useUpdateOrgUserMutation,
} from "features/api"
import usePermissions from "helpers/hooks/usePermissions"
import { USER_ROLES } from "helpers/utils/constants"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"

import type { UserOrgRole } from "types/users.types"

import { SectionCard, SectionCardHeader } from "../styled/containers"
import Spinner from "../common/Spinner"
import CreateUserButton from "./CreateUserButton"

interface OrgUsersProps {
  orgId: number | null
}
interface Row {
  id: number
  username: string
  role: string
}

const OrgUsers: FC<OrgUsersProps> = ({ orgId }) => {
  const { t } = useTranslation()
  const { isAdmin } = usePermissions()
  const { currentData: usersRoles, isFetching: usersFetching } = useOrgUsersQuery(
    {
      orgId: orgId as number,
    },
    { skip: !orgId },
  )
  const [deleteUser] = useDeleteOrgUserMutation()
  const [updateUser] = useUpdateOrgUserMutation()

  const sortedUsers = useMemo(
    () => fp.sortBy(({ username }) => username.toLowerCase(), usersRoles?.users),
    [usersRoles],
  )
  const rolesByUser: { [username: string]: UserOrgRole } = useMemo(
    () => fp.keyBy((role) => role.username, usersRoles?.roles),
    [usersRoles],
  )
  const [rows, setRows] = useState<Row[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }
  const handleDeleteClick = (id: GridRowId, row: Row) => async () => {
    setRows(rows.filter((row) => row.id !== id))

    snackbarMutation({
      mutation: deleteUser({ username: row.username, orgId: orgId as number }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.DELETING_ITEM", { item: t("generic.USER") }),
      ),
      getSuccessMessage: () => t("users.USER_DELETED"),
    }).catch()
  }
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const processRowUpdate = async (newRow: Row, oldRow: Row) => {
    // Optimistic update
    setRows((rows) => rows.map((row) => (row.id === newRow.id ? newRow : row)))

    try {
      await snackbarMutation({
        mutation: updateUser({
          username: oldRow.username,
          orgId: orgId as number,
          ...(newRow.role in USER_ROLES
            ? { role: newRow.role as keyof typeof USER_ROLES }
            : {}),
        }).unwrap(),
        getErrorMessage: buildGetErrorMessage(
          t("error.UPDATING_ITEM", { item: t("generic.USER") }),
        ),
        getSuccessMessage: () => t("users.USER_UPDATED"),
      })
      return newRow
    } catch {
      // Ups
      setRows((rows) => rows.map((row) => (row.id === newRow.id ? oldRow : row)))
      return oldRow
    }
  }

  useEffect(
    () =>
      setRows(
        (sortedUsers || []).map(({ id, username }) => ({
          id,
          username,
          role: rolesByUser[username]?.role ?? "-",
        })),
      ),
    [sortedUsers, rolesByUser],
  )
  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: t("generic.USERNAME"),
      minWidth: 200,
      width: 400,
      editable: false,
    },
    {
      field: "role",
      headerName: t("generic.ROLE"),
      minWidth: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: Object.values(USER_ROLES),
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("generic.ACTIONS"),
      minWidth: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              disabled={!isAdmin}
              key="save"
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              disabled={!isAdmin}
              key="cancel"
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            disabled={!isAdmin}
            key="edit"
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            disabled={!isAdmin}
            key="delete"
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, row)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  return (
    <SectionCard>
      <SectionCardHeader sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography component="h5" variant="h5">
          {t("generic.USERS")}
        </Typography>
        {isAdmin && <CreateUserButton />}
      </SectionCardHeader>
      {usersFetching ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
        </Box>
      )}
    </SectionCard>
  )
}

export default OrgUsers
