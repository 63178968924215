import { useState, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Stack, CircularProgress } from "@mui/material"
import { MuiOtpInput } from "mui-one-time-password-input"
import fp from "lodash/fp"

import { useUserOTPVerifyMutation } from "features/api"
import { snackbarMutation, buildGetErrorMessage } from "helpers/utils/mutations"

interface OTPVerifierProps {
  username: string
  onComplete: () => Promise<void>
}

const OTPVerifier = ({ username, onComplete }: OTPVerifierProps) => {
  const { t } = useTranslation()
  const [otp, setOtp] = useState("")

  const handleChange = useCallback(
    (value: string) => {
      setOtp(value)
    },
    [setOtp],
  )

  const [otpVerify, { isLoading: isVerifying }] = useUserOTPVerifyMutation()

  const otpVerifyWithSnackbar = useCallback(
    async (value) => {
      const req = { username, token: value }
      const mutation = otpVerify(req).unwrap()
      return snackbarMutation({
        mutation,
        getErrorMessage: buildGetErrorMessage(
          t("error.ENABLING_ITEM", {
            item: t("account_settings.TWO_FA").toLowerCase(),
            count: 1,
            context: "female",
          }),
        ),
        getSuccessMessage: () =>
          t("success.ENABLING_ITEM", {
            item: t("account_settings.TWO_FA"),
            count: 1,
            context: "female",
          }),
      }).catch()
    },
    [username, otpVerify, t],
  )
  const verify = useMemo(
    () => fp.throttle(1000, (value) => otpVerifyWithSnackbar(value).then(onComplete)),
    [onComplete, otpVerifyWithSnackbar],
  )
  const handleComplete = (value: string) => {
    return verify(value)?.catch(() => {})
  }

  return (
    <>
      <Stack direction="row" justifyContent={"center"} alignItems={"center"} gap={4}>
        <MuiOtpInput
          value={otp}
          onChange={handleChange}
          onComplete={handleComplete}
          autoFocus
          length={6}
          sx={{
            maxWidth: "450px",
            "& .MuiTextField-root": { margin: 0 },
            "& .MuiOutlinedInput-input": { padding: "10px", height: "30px" },
          }}
          gap={"10px"}
        />
        {isVerifying && <CircularProgress size={30} />}
      </Stack>
    </>
  )
}

export default OTPVerifier
