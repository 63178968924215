import type { FC, PropsWithChildren, ReactNode } from "react"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { useTheme } from "@mui/system"

interface ILoadingButtonProps extends PropsWithChildren<any> {
  loading: boolean
  styles?: object
  variant?: "outlined" | "text" | "contained"
  icon?: ReactNode
  type?: "submit" | "button"
  "aria-label"?: React.ComponentProps<"button">["aria-label"]
}
const LoadingButton: FC<ILoadingButtonProps> = ({
  children,
  loading,
  styles,
  onClick,
  variant = "text",
  icon,
  disabled = false,
  type = "submit",
  "aria-label": ariaLabel = "submit",
}) => {
  const theme = useTheme()

  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      sx={{
        height: "35px",
        display: "flex",
        gap: 1,
        px: 2,
        ...styles,
      }}
      type={type}
      aria-label={ariaLabel}
    >
      {loading && (
        <CircularProgress
          size={20}
          data-testid="progressbar"
          sx={{
            color: variant === "contained" ? "inherit" : theme.palette.text.primary,
            m: icon ? "2px" : "0",
          }}
        />
      )}
      {!loading && icon}
      {children}
    </Button>
  )
}

export const LightLoadingButton: FC<ILoadingButtonProps> = ({
  children,
  styles,
  ...props
}) => {
  const theme = useTheme()
  return (
    <LoadingButton
      {...props}
      styles={{
        width: "100%",
        my: 1,
        backgroundColor: "#ffffff",
        color: theme.palette.grey[800],
        height: "35px",
        border: `1px solid ${theme.palette.neutral[400]}`,
        ":hover": {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.neutral[200],
          borderColor: theme.palette.neutral[500],
        },
        ...styles,
      }}
    >
      {children}
    </LoadingButton>
  )
}

export default LoadingButton
