import { stringify as qsStringify } from "query-string"

import type { CreateTokenRes, RefreshTokenRes } from "types/api.types"
import type {
  LoginReq,
  LoginRes,
  LoginOTPReq,
  LoginOTPRes,
  LoginOAuthReq,
  LoginOAuthRes,
  ValidateOAuthReq,
  ValidateOAuthRes,
} from "types/auth.types"
import type {
  ChangePasswordReq,
  ChangePasswordRes,
  RecoverReq,
  ResetPasswordReq,
  ResetPasswordRes,
} from "types/password.types"
import type {
  CreateUserReq,
  CreateUserRes,
  ActivateUserReq,
  ActivateUserRes,
  UserReq,
  UserRes,
  UserUpdateReq,
  UserOTPRegisterReq,
  UserOTPRegisterRes,
  UserOTPVerifyReq,
  UserOTPVerifyRes,
  UserOTPDisableReq,
  UserOTPDisableRes,
  UserOTPRecoverGenerateReq,
  UserOTPRecoverGenerateRes,
} from "types/users.types"

import { filterObject } from "helpers/utils/common"
import { api } from "./base"

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    user: builder.query<UserRes, UserReq>({
      query: ({ username }) => ({
        url: `/v1/users/${encodeURIComponent(username)}`,
      }),
      providesTags: (_result, _error, { username }) => [{ type: "User", id: username }],
    }),
    userUpdate: builder.mutation<null, UserUpdateReq>({
      query: ({ username, ...rest }) => ({
        url: `/v1/users/${encodeURIComponent(username)}`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (_result, _error, { username }) => [
        { type: "User", id: username },
      ],
    }),
    activateUser: builder.mutation<ActivateUserRes, ActivateUserReq>({
      query: ({ username, secret, password }) => ({
        url: `/v1/users/${username}/activation`,
        method: "POST",
        body: { secret, password },
      }),
    }),
    changePassword: builder.mutation<ChangePasswordRes, ChangePasswordReq>({
      query: ({ username, current_password, new_password, otp_value }) => {
        const body = filterObject(
          { username, current_password, new_password, otp_value },
          (_, value) => value !== undefined,
        )
        return {
          url: `/v1/users/${encodeURIComponent(username)}/password`,
          method: "PUT",
          body,
        }
      },
      invalidatesTags: (_result, _error, { username }) => [
        { type: "User", id: username },
      ],
    }),
    createUser: builder.mutation<CreateUserRes, CreateUserReq>({
      query: ({ orgId, username, brand, role }) => ({
        url: `/v1/orgs/${orgId}/users`,
        method: "POST",
        body: {
          brand,
          username,
          role,
        },
      }),
      invalidatesTags: (_result, _error, { orgId }) => [
        { type: "User", id: `Organization(${orgId})` },
      ],
    }),
    permanentToken: builder.mutation<CreateTokenRes, void>({
      query: () => ({
        url: "/v1/permanent_token",
        method: "POST",
      }),
    }),
    refreshToken: builder.query<RefreshTokenRes, null>({
      query: () => ({
        url: "/v1/tokens/refresh",
        method: "POST",
      }),
    }),
    resetPassword: builder.mutation<ResetPasswordRes, ResetPasswordReq>({
      query: ({ secret, username, password }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/recover`,
        method: "PUT",
        body: { guid: secret, new_password: password },
      }),
    }),
    login: builder.mutation<LoginRes, LoginReq>({
      query: (credentials) => ({
        url: "/v1/tokens",
        method: "POST",
        body: credentials,
      }),
    }),
    loginOTP: builder.mutation<LoginOTPRes, LoginOTPReq>({
      query: ({ accessToken, otpValue }) => ({
        url: "/v1/tokens/otp",
        method: "POST",
        headers: { authorization: `Bearer ${accessToken}` },
        body: { otp_value: otpValue },
      }),
    }),
    loginOAuth: builder.mutation<LoginOAuthRes, LoginOAuthReq>({
      query: ({ provider, brand }) => ({
        url: "/v1/tokens/oauth/authorize",
        method: "POST",
        body: { provider, brand },
      }),
    }),
    validateOAuth: builder.mutation<ValidateOAuthRes, ValidateOAuthReq>({
      query: (oAuthData) => ({
        url: "/v1/tokens/oauth/verify",
        method: "POST",
        body: qsStringify(oAuthData),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }),
    }),
    sendRecoverEmail: builder.mutation<boolean | null, RecoverReq>({
      query: ({ username, brand }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/recover`,
        method: "POST",
        body: { email: username, brand },
      }),
    }),
    userOTPRegister: builder.mutation<UserOTPRegisterRes, UserOTPRegisterReq>({
      query: ({ username }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/otp/register`,
        method: "POST",
      }),
    }),
    userOTPVerify: builder.mutation<UserOTPVerifyRes, UserOTPVerifyReq>({
      query: ({ username, token }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/otp/verify`,
        method: "POST",
        body: { token },
      }),
      invalidatesTags: (_result, _error, { username }) => [
        { type: "User", id: username },
      ],
    }),
    userOTPDisable: builder.mutation<UserOTPDisableRes, UserOTPDisableReq>({
      query: ({ username }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/otp/disable`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, { username }) => [
        { type: "User", id: username },
      ],
    }),
    userOTPRecoverGenerate: builder.mutation<
      UserOTPRecoverGenerateRes,
      UserOTPRecoverGenerateReq
    >({
      query: ({ username }) => ({
        url: `/v1/users/${encodeURIComponent(username)}/otp/recover/generate`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, { username }) => [
        { type: "User", id: username },
      ],
    }),
    // Not in use
    register: builder.mutation({
      query: (userData) => ({
        url: "/v1/users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
  }),
})
