import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import fp from "lodash/fp"

import { useOrgsQuery } from "features/api"
import { selectCurrentUser, selectCurrentUserGlobalRole } from "features/store/authSlice"
import {
  selectCurrentOrgId,
  selectCurrentOrgUserRole,
  setOrg,
  setOrgUserRole,
} from "features/store/orgSlice"
import { ORGS_TIME, USER_ROLES } from "helpers/utils/constants"

const useOrgs = () => {
  const dispatch = useDispatch()
  const { orgId: urlOrgId } = useParams()
  const [orgsInfoLoading, setOrgsInfoLoading] = useState()
  const currentOrgId = useSelector(selectCurrentOrgId)
  const user = useSelector(selectCurrentUser)
  const role = useSelector(selectCurrentOrgUserRole)
  const userGlobalRole = useSelector(selectCurrentUserGlobalRole)
  // FIXME: the role of the current user needs to be part of the reply from the useOrgsQuery
  // current user roles:
  //   admin: when global role is backoffice_admin or reseller.
  //   viewer: when username (mail address) contains the string "viewer".
  //   editor: default.
  const userRole =
    userGlobalRole === "backoffice_admin" || userGlobalRole === "reseller"
      ? USER_ROLES.admin
      : user?.includes("viewer")
        ? USER_ROLES.viewer
        : USER_ROLES.editor
  const {
    data: orgs,
    isFetching: orgsFetching,
    isLoading: orgsLoading,
    isError,
  } = useOrgsQuery(
    {
      user,
    },
    {
      pollingInterval: ORGS_TIME,
    },
  )

  const urlOrgInUserOrgs = useMemo(
    () => orgs?.orgs?.find((org) => urlOrgId && String(org.id) === urlOrgId),
    [orgs, urlOrgId],
  )
  const rolesByOrg = useMemo(() => fp.keyBy((role) => role.org_id, orgs?.roles), [orgs])

  const currentOrgUserRole = useMemo(
    () => rolesByOrg[currentOrgId]?.role ?? userRole,
    [currentOrgId, rolesByOrg, userRole],
  )

  const getOrgById = (orgId) => orgId && orgs?.orgs?.find(({ id }) => id === orgId)

  useEffect(
    () => {
      const dispatchOrgInfo = async () => {
        if (orgsFetching) {
          // We are still loading the orgs
        } else if (urlOrgInUserOrgs && String(currentOrgId) !== urlOrgId) {
          dispatch(
            setOrg({
              id: urlOrgInUserOrgs.id,
              userRole: rolesByOrg[urlOrgInUserOrgs.id]?.role ?? userRole,
              name: urlOrgInUserOrgs.name,
            }),
          )
        } else if (orgs?.orgs?.find(({ id }) => id === currentOrgId)) {
          // No need to switch orgs, the current one is a valid option
        } else if (orgs?.orgs?.length > 0) {
          dispatch(
            setOrg({
              id: orgs?.orgs[0]?.id,
              userRole: rolesByOrg[urlOrgInUserOrgs.id]?.role ?? userRole,
              name: orgs?.orgs[0]?.name,
            }),
          )
        } else {
          dispatch(
            setOrg({
              id: null,
              userRole,
              name: null,
            }),
          )
        }
      }
      const setOrgInfo = async () => {
        if (orgsFetching) {
          setOrgsInfoLoading(true)
        }
        await dispatchOrgInfo()
        setOrgsInfoLoading(false)
      }
      setOrgInfo()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgs, orgsFetching, dispatch],
  )

  useEffect(() => {
    if (role !== currentOrgUserRole) {
      dispatch(setOrgUserRole(currentOrgUserRole))
    }
  }, [role, currentOrgUserRole, dispatch])

  const dispatchOrg = ({ id, name }) => {
    dispatch(setOrg({ id, role: rolesByOrg[id]?.role ?? userRole, name }))
  }

  return {
    orgs: orgs?.orgs,
    orgsFetching,
    orgsLoading,
    orgsInfoLoading,
    isError,
    dispatchOrg,
    getOrgById,
  }
}

export default useOrgs
