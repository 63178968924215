import { Box, Container } from "@mui/material"
import { useMemo } from "react"
import { useSelector } from "react-redux"

import { useLastLocationsQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { formatLocationData } from "helpers/formatters/dataFormatters"
import { DASHBOARD_PADDING, FOOTER_HEIGHT, NAVBAR_HEIGHT } from "helpers/utils/constants"
import GeoPlot from "widgets/plots/geo/GeoPlot"
import Spinner from "widgets/common/Spinner"

function Map() {
  const orgId = useSelector(selectCurrentOrgId)
  const { currentData, isFetching } = useLastLocationsQuery(
    { params: { org_id: orgId as number } },
    { skip: !orgId },
  )

  const flattenLocationData = useMemo(
    () => formatLocationData(currentData),
    [currentData],
  )

  return (
    <>
      <Box
        sx={{
          width: `calc(100% + (${DASHBOARD_PADDING}*2))`,
          height: `calc(100vh - ${NAVBAR_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
          position: "relative",
          mt: `-${DASHBOARD_PADDING}`,
          ml: `-${DASHBOARD_PADDING}`,
          mr: `-${DASHBOARD_PADDING}`,
        }}
      >
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        >
          {isFetching ? <Spinner /> : <GeoPlot data={flattenLocationData} />}
        </Container>
      </Box>
    </>
  )
}

export default Map
