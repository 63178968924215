import { api } from "./base"

import { alertsApi } from "./alerts"
import { devicesApi } from "./devices"
import { deviceGroupsApi } from "./deviceGroups"
import { usersApi } from "./users"

import { conductorsApi } from "./conductors"
import { powerlinesApi } from "./powerlines"
import { spansApi } from "./spans"
import { towersApi } from "./towers"

export { api } from "./base"

export const {
  // dashboard and telemetry related
  useCellDataQuery,
  useCellViewQuery,
  useDashboardByDeviceQuery,
  useDeviceTelemetryQuery,
  useLazyDeviceTelemetryQuery,
  useLastLocationsQuery,
  useUpdateLocationMutation,

  // orgs related
  useOrgsQuery,
  useOrgUsersQuery,
  useDeleteOrgUserMutation,
  useUpdateOrgUserMutation,
  useUpdateOrgNameMutation,
  useUpdateOrgSettingsMutation,
} = api

export const {
  useAddDeviceAlertSettingMutation,
  useAlertsQuery,
  useLimitedAlertsQuery,
  useDeleteAlertSettingMutation,
  useDeviceAlertTypesQuery,
  useGroupAlertsInfoQuery,
  useMarkAlertAsViewedMutation,
  useOrgAlertSettingsQuery,
  useOrgAlertSettingsStatesQuery,
  useUpdateDeviceAlertSettingMutation,
  useUpdateOrgAlertStateMutation,
} = alertsApi

export const {
  useActivateDeviceMutation,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useDeviceQuery,
  useDevicesQuery,
  useSellDeviceMutation,
  useUpdateDeviceNameMutation,
} = devicesApi

export const {
  useAddDeviceToGroupMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useDeviceGroupQuery,
  useDeviceGroupsQuery,
  useRemoveDeviceFromGroupMutation,
  useRemoveDevicesFromGroupMutation,
  useUpdateDeviceGroupNameMutation,
} = deviceGroupsApi

export const {
  useUserQuery,
  useUserUpdateMutation,
  useActivateUserMutation,
  useChangePasswordMutation,
  useCreateUserMutation,
  usePermanentTokenMutation,
  useRefreshTokenQuery,
  useResetPasswordMutation,
  useLoginMutation,
  useLoginOTPMutation,
  useLoginOAuthMutation,
  useValidateOAuthMutation,
  useSendRecoverEmailMutation,
  useUserOTPRegisterMutation,
  useUserOTPVerifyMutation,
  useUserOTPDisableMutation,
  useUserOTPRecoverGenerateMutation,
} = usersApi

export const { useConductorsQuery, useConductorQuery } = conductorsApi
export const { usePowerlinesQuery, usePowerlineQuery } = powerlinesApi
export const { usePowerlineSpansQuery, useSpansQuery, useSpanQuery } = spansApi
export const { usePowerlineTowersQuery, useTowersQuery, useTowerQuery } = towersApi
